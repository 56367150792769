/* src/components/Navbar.css */
.navbar {
  background: #F5CBA7;
  padding: 10px 0;
}

.nav-list {
  list-style-type: none;
  padding: 0;
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center-align items horizontally */
}