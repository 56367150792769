/* src/styles.css */
.hero {
  text-align: center;
  padding: 20px;
}

.hero img {
  max-width: 80%;
}

.container {
  display: flex;
  width: 100%;
  padding: 0 5% 0 5%;
  font-size: 24px;
  height: 200px;
}

.block-left {
  background-color: #ff9999;
  display:flex; 
  width: 30%; 
  align-items:center;
  justify-content:center;
}

.block-center {
  background-color: #addfad;
  display:flex; 
  width: 30%; 
  align-items:center;
  justify-content:center;
}

.block-right {
  background-color: #B6D0E2;
  display:flex; 
  width: 30%; 
  align-items:center;
  justify-content:center;
}

.block-footer {
  background-color: #F5CBA7;
  display:flex; 
  width: 90%; 
  align-items:center;
  justify-content:center;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}